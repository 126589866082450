<template>
  <div class="proof-of-delivery-view">
    <ui-ctk-icon
      name="checklist-alt"
      class="tw-text-xl"
      data-test="icon"
    />
    <div class="tw-flex-grow tw-flex tw-justify-start tw-items-center tw-flex-wrap">
      <ui-badge
        color="blue"
        data-test="badge"
      >
        <span
          class="proof-of-delivery-view__badge"
          v-text="$t('missions.proof_of_delivery_title')"
        />
      </ui-badge>
      <span
        v-if="importDate"
        class="proof-of-delivery-view__import-date"
        v-text="$t('missions.mission_detail.label.follow_up.import_date', { formattedDate : importDate })"
        data-test="import-date"
      />
    </div>
    <b-dropdown
      variant="link"
      dropdown
      no-caret
      class="ctk-dropdown tw-flex tw-items-center"
      boundary="window"
      :right="true"
      @click.stop="requestFiles"
      data-test="pod-actions-dd"
    >
      <template #button-content>
        <div class="tw-flex tw-m-auto">
          <ui-material-icon
            name="more_vert"
            data-test="dd-btn-icon"
          />
        </div>
      </template>
      <b-dropdown-item
        v-if="files.view"
        :href="files.view"
        target="_blank"
        class="proof-of-delivery-view__action"
        data-test="view"
      >
        {{ $t('app.buttons.view') }}
      </b-dropdown-item>
      <b-dropdown-item
        v-if="files.download"
        :href="files.download"
        target="_blank"
        class="proof-of-delivery-view__action"
        data-test="download"
      >
        {{ $t('app.buttons.download') | capitalize }}
      </b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item
        @click.stop="reupload"
        class="proof-of-delivery-view__action"
        data-test="edit"
      >
        {{ $t('missions.modify') }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import axios from '@/resources/axios'

  /**
   * @module component - proofOfDeliveryView
   */
  export default {
    name: 'ProofOfDeliveryView',
    data () {
      return {
        error: false,
        success: false,
        files: {
          view: null,
          download: null
        },
        fileImportDate: null
      }
    },
    mounted () {
      this.requestFiles()

      // @ts-ignore
      this.fileImportDate = this.getCurrentMission?.proof_of_delivery?.files?.[0]?.created_at ?? null
    },
    computed: {
      ...mapGetters('missions', [
        'getCurrentMissionUuid',
        'getCurrentMission'
      ]),
      ...mapGetters('auth', ['getCid']),
      /**
       * @function downloadFileUrl
       */
      downloadFileUrl () {
        return `v2/companies/${this.getCid}/missions/${this.getCurrentMissionUuid}/proof-of-delivery`
      },
      isPODAvailable () {
        return this.getCurrentMissionState === 'completed' && this.getCurrentMission.proof_of_delivery.state === 'available'
      },
      importDate () {
        return this.fileImportDate ? this.$moment(this.fileImportDate).format('Do MMM YYYY') : null
      }
    },
    methods: {
      ...mapActions('missions', [
        'updateProofOfDelivery'
      ]),
      /**
       * @function reupload
       */
      reupload () {
        this.updateProofOfDelivery({
          value: {
            uuid: this.getCurrentMissionUuid,
            status: 'pending'
          }
        })
          .then(() => {
            this.fileImportDate = new Date()
          })
      },
      requestFiles () {
        const downloadFile = axios.get(this.downloadFileUrl, {
          params: {
            disposition: 'attachment'
          }
        })
          .then((response) => {
            this.files.download = response.data.url
            return response
          })

        const viewFile = axios.get(this.downloadFileUrl)
          .then((response) => {
            this.files.view = response.data.url
            return response
          })

        /**
         * Wait for both files to be ready, to open the popover.
         */
        return Promise.all([
          viewFile,
          downloadFile
        ])
          .then((response) => {
            this.success = true
            this.popoverVisible = true

            this.$emit('success', response)
          })
          .catch(() => {
            this.$emit('failed')
          })
          .finally(() => {
            /**
             * Reset the button state to it's default state after some seconds.
             */
            setTimeout(() => {
              this.resetAsDefault()
            }, 2000)
          })
      },
      resetAsDefault () {
        this.success = false
      }
    },
    watch: {
      downloadFileUrl () {
        this.success = false
        this.requestFiles()
        // @ts-ignore
        this.fileImportDate = this.getCurrentMission?.proof_of_delivery?.files?.[0]?.created_at ?? null
      },
      getCurrentMission () {
        // @ts-ignore
        this.fileImportDate = this.getCurrentMission?.proof_of_delivery?.files?.[0]?.created_at ?? null
      }
    }
  }
</script>

<style lang="scss" scoped>
.proof-of-delivery-view {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(216, 216, 216, var(--tw-border-opacity));
  border-left-color: rgba(40, 118, 150, var(--tw-border-opacity));
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 1px;
  border-left-width: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0;
  -webkit-flex: 1 1 0;
  flex: 1 1 0;
  height: auto;
  font-size: 1rem;
  overflow: hidden;
  padding: 1rem;
  padding-left: 1.25rem;
  grid-gap: 1rem;
  gap: 1rem;
}
@media (min-width: 770px) {
  .proof-of-delivery-view {
    min-height: 58px;
  }
}
.proof-of-delivery-view__import-date {
  font-size: 0.875rem;
  margin: 0px;
}
@media (min-width: 1024px) {
  .proof-of-delivery-view__import-date {
    margin: auto;
  }
}
.proof-of-delivery-view__action {
  --tw-text-opacity: 1;
  color: rgba(103, 106, 108, var(--tw-text-opacity));
}
.proof-of-delivery-view__action .ui-button {
  width: 100%;
  padding: 0;
  border: none;
  min-height: 0;
  background-color: inherit;
  color: inherit;
}
.proof-of-delivery-view::v-deep .dropdown-menu {
  border-radius: 0.25rem;
  padding: 0px;
  box-shadow: 2px 2px 3px 0 $gray;
}
.proof-of-delivery-view::v-deep .dropdown-menu .dropdown-item {
  padding: 0px;
  color: inherit;
  text-align: center;
}
.proof-of-delivery-view::v-deep .dropdown-menu .dropdown-divider {
  margin: 0px;
}
</style>
