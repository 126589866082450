<template>
  <div class="mission-detail-header">
    <div class="mission-detail-header-title">
      <h3
        v-text="$t('missions.detail.title.with_ref', { ref: getCurrentMissionReference })"
        data-test="title"
      />
      <ui-badge
        v-if="!!followUpStatus"
        color="red"
      >
        <template #left-icon>
          <ui-ctk-icon
            name="warning"
            data-test="icon"
          />
        </template>
        <span
          class="mission-detail-header-title__badge"
          v-text="`${followUpStatus}`"
        />
      </ui-badge>
      <ui-badge
        v-if="!!badgeText"
        :color="badgeColor"
      >
        <template
          #left-icon
          v-if="hasIcon"
        >
          <ui-ctk-icon
            :name="badgeIcon"
            data-test="icon"
          />
        </template>
        <span
          class="mission-detail-header-title__badge"
          v-text="`${badgeText}`"
        />
      </ui-badge>
    </div>

    <ui-button
      v-b-tooltip.hover
      :title="$t('close') | capitalize"
      class="mission-detail-header__close"
      variant="link"
      type="button"
      data-test="close-button"
      @click="resetCurrentMission"
    >
      <template
        #left-icon
      >
        <ui-ctk-icon
          name="close"
          data-test="icon"
        />
      </template>
    </ui-button>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters, mapActions } from 'vuex'

  /**
   * @module components - MissionDetailHeader
   */
  export default defineComponent({
    name: 'MissionDetailHeader',
    computed: {
      ...mapGetters('missions', [
        'getCurrentMission',
        'getCurrentMissionReference',
        'getCurrentMissionState',
        'getBilling'
      ]),
      badgeColor () {
        if (this.isCancelled) return 'red'
        if (this.isAwaitingDelivery) return 'green'

        return 'blue'
      },
      badgeText () {
        if (this.isCancelled) return this.$i18n.t('missions.mission_cancelled')
        if (this.isAwaitingBilling) return this.$i18n.t('missions.mission_item.late_text.billing_pending')
        if (this.isAwaitingPOD) return this.$i18n.t('missions.mission_item.late_text.pod_pending')
        if (this.isAwaitingDelivery) return this.$i18n.t('missions.mission_item.late_text.delivery_pending')
        if (this.isAwaitingPickup) {
          return this.isInProgress ? this.$i18n.t('missions.mission_item.late_text.pickup_pending') : this.$i18n.t('missions.pickup_begin_in_x_time', { time: this.pickupTimeLeft })
        }

        if (this.isCompleted) {
          return this.$i18n.t('missions.mission_item.completed')
        }

        return null
      },
      badgeIcon () {
        if (this.isAwaitingPickup) {
          return this.isInProgress ? null : 'info'
        }
      },
      hasIcon () {
        return !!this.badgeIcon
      },
      followUpStatus () {
        const { delivery } = this.getCurrentMission
        const isDeliveryLate = delivery.state === 'late'
        const isLimitExpired = this.isCmrPending && isDeliveryLate

        // @ts-ignore
        return isLimitExpired
          ? this.$i18n.t('missions.tracking_time_limit_expired')
          : null
      },
      pickupTimeLeft () {
        const { time_slot: timeSlot } = this.getCurrentMission.pickup
        return this.$moment(`${timeSlot.date} ${timeSlot.start_time}`, 'YYYY-MM-DD HH:mm').fromNow(true)
      },
      isCancelled () {
        return this.getCurrentMissionState === 'cancelled'
      },
      isPlanned () {
        const { state } = this.getCurrentMission
        return state === 'planned'
      },
      isInProgress () {
        const { state } = this.getCurrentMission
        return state === 'in_progress'
      },
      /**
       * Returns true if we're awaiting for pickup
       * @function isAwaitingPickup
       * @returns {boolean}
       */
      isAwaitingPickup () {
        const { pickup } = this.getCurrentMission
        return ['late', 'pending'].includes(pickup.state)
      },
      /**
       * Returns true if we're awaiting for delivery
       * @function isAwaitingDelivery
       * @returns {boolean}
       */
      isAwaitingDelivery () {
        const { pickup, delivery } = this.getCurrentMission
        return pickup.state === 'completed' && delivery.state === 'pending'
      },
      /**
       * Returns true if we're awaiting the POD
       * @function isAwaitingPOD
       * @returns {boolean}
       */
      isAwaitingPOD () {
        const { delivery, proof_of_delivery: POD } = this.getCurrentMission
        const isDeliveryDone = ['late', 'completed'].includes(delivery.state)
        return isDeliveryDone && POD.state === 'pending'
      },
      /**
       * Returns true if the mission is awaiting for billing
       * @function isAwaitingBilling
       * @returns {boolean}
       */
      isAwaitingBilling () {
        const { state, pickup, delivery, proof_of_delivery: POD } = this.getCurrentMission
        const isCompleted = state === 'completed'
        const isPickupCompleted = pickup.state === 'completed'
        const isDeliveryCompleted = delivery.state === 'completed'

        return !isCompleted &&
          isPickupCompleted &&
          isDeliveryCompleted &&
          POD &&
          POD.state !== 'pending'
      },
      /**
       * Returns true if the CMR is pending.
       * @function isCmrPending
       * @returns {boolean}
       */
      isCmrPending () {
        const { proof_of_delivery: POD } = this.getCurrentMission
        return POD && POD.state === 'pending'
      },
      isCompleted () {
        const { state } = this.getCurrentMission
        return state === 'completed' || this.hasPod
      },
      /**
       * @function hasPod
       * @returns {boolean}
       */
      hasPod () {
        const { proof_of_delivery: pod } = this.getCurrentMission
        return pod && pod.state === 'available'
      },
      // ? previously called 'invoiceLength'
      hasInvoices () {
        return this.getBilling.invoices && this.getBilling.invoices.length
      },
      // check if none of its invoices has been paid yet
      hasNoPaidInvoice () {
        if (!this.hasInvoices) {
          return false
        }

        return this.getBilling.invoices.every(invoice => !invoice.payment)
      },
      hasInvoiceNotPaid () {
        let response = false
        if (this.hasInvoices) {
          this.getBilling.invoices.forEach(invoice => {
            if (!invoice.payment) {
              response = true
            }
          })
        }
        return response
      }
    },
    methods: {
      ...mapActions('missions', [
        'resetCurrentMission'
      ])
    }
  })
</script>

<style lang="scss" scoped>
.mission-detail-header {
  --tw-bg-opacity: 1;
  background-color: rgba(242, 242, 242, var(--tw-bg-opacity));
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 88px;
  flex: 0 0 88px;
  padding-left: 55px;
  padding-right: 0.875rem;
}
@media (min-width: 770px) {
  .mission-detail-header {
    min-height: 64px;
    flex: 0 0 64px;
    padding-left: 36px;
  }
}
.mission-detail-header-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-column-gap: 12px;
  -moz-column-gap: 12px;
  grid-column-gap: 12px;
  column-gap: 12px;
}
.mission-detail-header-title h3 {
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: 0px;
  --tw-text-opacity: 1;
  color: rgba(40, 118, 150, var(--tw-text-opacity));
}
</style>
