<template>
  <mission-detail-section
    class="goods-infos"
  >
    <template
      #title
    >
      <mission-detail-section-title
        :title="$tc('good', 2) | capitalize"
      >
        <ui-ctk-icon
          name="pallets"
          class="tw-text-blue-500 tw-text-3xl"
        />
      </mission-detail-section-title>
    </template>

    <div class="goods-infos__items tw-flex tw-flex-wrap">
      <mission-detail-group-item
        :title="$t('app.labels.load.type') | capitalize"
        data-test="type"
      >
        <template
          v-if="mission.load.type === 'custom'"
        >
          {{ $t('prepared_goods') | capitalize }}
        </template>
        <template
          v-else-if="mission.load.type === 'pallets'"
        >
          {{ $t('pallets') | capitalize }}
        </template>
        <template
          v-else
        >
          {{ $t('unprepared_goods') | capitalize }}
        </template>
      </mission-detail-group-item>
      <mission-detail-group-item
        :title="$t('app.labels.load.format') | capitalize"
        data-test="format"
      >
        <template
          v-if="mission.load.type === 'pallets'"
        >
          {{ mission.load.format }} cm
        </template>
        <template
          v-else
        >
          {{ `${mission.load.width}x${mission.load.length} cm` }}
        </template>
      </mission-detail-group-item>
      <mission-detail-group-item
        v-if="mission.load.type === 'pallets'"
        :title="$t('app.labels.load.quantity') | capitalize"
        data-test="quantity"
      >
        {{ $n(mission.load.quantity) }}
      </mission-detail-group-item>
      <mission-detail-group-item
        :title="$t('app.labels.load.max_height') | capitalize"
        data-test="height"
      >
        <span
          v-text="`${$n(mission.load.height)} cm`"
        />
      </mission-detail-group-item>
      <mission-detail-group-item
        :title="$t('app.labels.load.weight') | capitalize"
        data-test="weight"
      >
        <span
          v-text="$t('weight_unit', {
            weight: $n(mission.load.weight)
          })"
        />
      </mission-detail-group-item>
    </div>
    <mission-detail-specificity-group
      v-if="mission.load.description || adr || adjustment"
      class="lg:tw-max-w-80 tw-mt-6"
      data-test="load-group"
    >
      <mission-detail-specificity-item
        v-if="adjustment"
        :icon="'adjust'"
        :title="$t('app.labels.adjustment')"
        :variant="'info'"
        :content="adjustment.comment"
        data-test="adjustment"
      />
      <mission-detail-specificity-item
        v-if="adr"
        :icon="'skull'"
        :title="$t('app.labels.adr_load')"
        :variant="'info'"
        :content="adr.comment"
        data-test="adr"
      />
      <mission-detail-specificity-item
        v-if="mission.load.description"
        :icon="'comment'"
        :title="$t('comment')"
        :content="mission.load.description"
        data-test="description"
      />
    </mission-detail-specificity-group>
    <mission-detail-group-item
      v-if="hasPallets"
      :title="$t('app.labels.load.pallets_detail')"
      class="tw-mt-4"
      data-test="pallets"
    >
      <div class="tw-flex tw-flex-col md:tw-flex-row">
        <goods-pallet
          v-for="(pallet, k) in mission.load.pallets"
          :key="k"
          :pallet="pallet"
          class="md:not-last:tw-mr-3 tw-mb-3"
          data-test="pallet"
        />
      </div>
    </mission-detail-group-item>
  </mission-detail-section>
</template>

<script>
  import { computed, defineComponent, toRefs } from '@vue/composition-api'

  import MissionDetailSection from '../../components/MissionDetailSection/index.vue'
  import MissionDetailSectionTitle from '../../components/MissionDetailSection/MissionDetailSectionTitle/index.vue'
  import MissionDetailSpecificityGroup from '../../components/MissionDetailSpecificityGroup/index.vue'
  import MissionDetailSpecificityItem from '../../components/MissionDetailSpecificityGroup/_subs/MissionDetailSpecificityItem/index.vue'
  import MissionDetailGroupItem from '../../components/MissionDetailGroupItem/index.vue'
  import GoodsPallet from './_subs/GoodsPallet/index.vue'

  /**
   * @module component - GoodsInfos
   */
  export default defineComponent({
    name: 'GoodsInfos',
    components: {
      MissionDetailSection,
      MissionDetailSectionTitle,
      MissionDetailGroupItem,
      MissionDetailSpecificityGroup,
      MissionDetailSpecificityItem,
      GoodsPallet
    },
    props: {
      mission: {
        type: Object,
        required: true
      }
    },
    setup (props) {
      const { mission } = toRefs(props)

      /**
       * @function getPriceLine
       * @param {string} lineKey
       * @returns {{
       *   key: string
       * } | undefined}
       */
      function getPriceLine (lineKey) {
        if (!mission.value.pricing || !mission.value.pricing.price_lines) return

        // @ts-ignore
        return mission.value.pricing?.price_lines?.find(line => line.key === lineKey)
      }

      const adr = computed(() => getPriceLine('adr_dangerous_goods_by_road'))
      const adjustment = computed(() => getPriceLine('shipper_load_incorrect_configuration'))

      /** @type {import('@vue/composition-api').ComputedRef<boolean>} */
      const hasPallets = computed(() => !!mission.value.load.pallets && mission.value.load.pallets.length)

      return {
        adr,
        adjustment,
        hasPallets
      }
    }
  })
</script>

<style lang="scss" scoped>
.goods-infos {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  position: relative;
}
.goods-infos::after {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 233, 233, var(--tw-bg-opacity));
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
}
.goods-infos__items {
  margin: -1rem;
}
.goods-infos__items .mission-detail-group-item {
  margin: 1rem;
  position: relative;
  flex: 1 0 calc(45% - 2rem);
}
@media (min-width: 770px) {
  .goods-infos__items .mission-detail-group-item {
    flex: 1 0 calc(18% - 1rem);
  }
  .goods-infos__items .mission-detail-group-item:not(:last-child) {
    position: relative;
  }
  .goods-infos__items .mission-detail-group-item:not(:last-child)::after {
    --tw-border-opacity: 1;
    border-color: rgba(216, 216, 216, var(--tw-border-opacity));
    border-style: solid;
    border-top-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-right-width: 0px;
    height: 100%;
    position: absolute;
    content: '';
    width: 1px;
    right: -1rem;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
.goods-infos .mission-detail-specificity-group {
  margin-bottom: 0px;
}
</style>
