<template>
  <div
    class="pricing-lines tw-flex tw-justify-between tw-text-secondary-text"
  >
    <component
      :is="cancelled ? 's' : 'div'"
      :class="{ cancelled: cancelled }"
      class="pricing-lines-label tw-pr-2 tw-whitespace-pre-line"
    >
      <slot name="label">
        {{ label | capitalize }}<br>
      </slot>
      <div
        v-if="comment"
        class="pricing-lines-comment tw-text-sm"
      >
        ({{ comment }})
      </div>
    </component>
    <component
      :is="cancelled ? 's' : 'div'"
      :class="{ cancelled: cancelled }"
      class="pricing-lines-slot"
    >
      <slot />
    </component>
  </div>
</template>

<script>
  /**
   * @module component - pricingLines
   * @param {string} label - Line label
   * @param {string} [comment=null] - Any additionnal comment to the price line
   * @param {boolean} [cancelled=false] - Specify if the line should be stripped or not
   *
   * @example <caption>Simple usage</caption>
   * <pricing-line :label="'Pricing line label'">
   *   123,45 €
   * </pricing-line>
   *
   * @example <caption>Slot usage</caption>
   * <pricing-line>
   *   <span class="more-complex">
   *     123,45 €
   *   </span>
   * </pricing-line>
   */
  export default {
    name: 'PricingLines',
    props: {
      label: {
        type: String,
        required: true
      },
      comment: {
        type: String,
        default: null
      },
      cancelled: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>

  .pricing-lines {
    position: relative;
    background-color: #FFF;
    line-height: 1.4;

    &-label {
      margin: auto 0;
    }

    &-slot {
      white-space: nowrap;
    }

    &-comment {
      color: $third-text;
    }

    & .cancelled {
      color: $third-text;
      opacity: 0.75;
    }
  }

</style>
