<template>
  <div class="pricing-layout">
    <slot />
  </div>
</template>

<script>
  /**
   * Generic pricing container
   * @module component - pricingLayout
   * @example
   * <pricing-layout>
   *   <pricing-lines label="First line">
   *     123,45 €
   *   </pricing-lines>
   * </pricing-layout>
   */
  export default {
    name: 'PricingLayout'
  }
</script>
