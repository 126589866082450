<template>
  <div class="mission-detail-directions-section">
    <mission-detail-direction-inline-section
      :mission="mission"
      class="tw-hidden md:tw-flex"
    />

    <div class="md:tw-hidden">
      <mission-detail-direction-section
        :mission="mission"
        :direction="'pickup'"
      />
      <mission-detail-direction-section
        :mission="mission"
        :direction="'delivery'"
      />
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import MissionDetailDirectionSection from './_subs/MissionDetailDirectionSection'
  import MissionDetailDirectionInlineSection from './_subs/MissionDetailDirectionInlineSection'

  export default defineComponent({
    name: 'MissionDetailDirectionsSection',
    components: {
      MissionDetailDirectionSection,
      MissionDetailDirectionInlineSection
    },
    props: {
      mission: {
        type: Object,
        required: true
      }
    }
  })
</script>

<style lang="scss">
.mission-detail-directions-section {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  position: relative;
}
@media (min-width: 770px) {
  .mission-detail-directions-section {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}
.mission-detail-directions-section::after {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 233, 233, var(--tw-bg-opacity));
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
}
.mission-detail-directions-section .mission-detail-direction-section:nth-child(1) {
  padding-bottom: 0;
}
.mission-detail-directions-section .mission-detail-section {
  padding: 0px;
}
.mission-detail-directions-section .mission-detail-section:first-of-type {
  margin-bottom: 2rem;
}
</style>
