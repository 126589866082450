<template>
  <div
    :class="{'is-open': hasDetailOpenned}"
    class="pricing-invoice-content"
  >
    <b-collapse
      id="pricingInvoiceContentCollapse"
      v-model="hasDetailOpenned"
    >
      <p
        data-test="further-information-title"
        class="title"
      >
        {{ $t('pricing.invoice.further_information') }}
      </p>

      <pricing-layout
        data-test="dates"
        class="tw-mb-4"
      >
        <pricing-lines
          :label="$t('pricing.invoice.issued_on')"
          data-test="issue-date"
        >
          <span>{{ $moment(invoice.issue_date).format('LL') }}</span>
        </pricing-lines>

        <pricing-lines
          v-if="!invoice.payment && invoice.state == 'prescribed'"
          :label="$t('pricing.invoice.sending_overdue')"
          data-test="payment-date"
        >
          <span>&nbsp;</span>
        </pricing-lines>

        <pricing-lines
          v-if="!invoice.payment && invoice.state != 'prescribed'"
          :label="$t('pricing.invoice.due_to')"
          data-test="payment-date"
        >
          <span>{{ $moment(invoice.due_date).format('LL') }}</span>
        </pricing-lines>
      </pricing-layout>
      <p
        v-if="invoice.lines && invoice.lines.length > 0 || invoice.payment"
        data-test="detailled-invoice-title"
        class="title"
      >
        {{ $t('pricing.invoice.detailed_invoice') }}
      </p>

      <pricing-layout
        class="tw-mb-4"
        data-test="detailled-invoice"
      >
        <pricing-lines
          v-for="line in invoice.lines"
          :key="line.key"
          :label="line.label"
          :cancelled="line.is_cancelled"
          data-test="invoice-lines"
        >
          <span
            v-if="line.amount"
            class="tw-text-blue-500 tw-text-right"
          >
            {{ getCurrencyFormat(line.amount) }}
          </span>
        </pricing-lines>

        <pricing-lines
          v-if="invoice.payment"
          :label="$t('pricing.invoice.payment.paid_on')"
          data-test="paid-on"
        >
          <span class="tw-text-blue-500 tw-text-right">
            {{ $moment(invoice.payment.issue_date).format('LL') }}
          </span>
        </pricing-lines>

        <pricing-lines
          v-if="invoice.payment"
          :label="$t('pricing.invoice.payment.reference')"
          data-test="payment-reference"
        >
          <span class="tw-text-blue-500 text-right">
            {{ invoice.payment.reference }}
          </span>
        </pricing-lines>
      </pricing-layout>

      <div
        v-if="hasDownloadButton"
        class="pricing-invoice-content__footer"
      >
        <ctk-btn-file
          :id="`download-button-${invoice.reference}`"
          :url="fileUrl"
          :popover-title="$t('app.labels.invoice')"
          :file-name="`invoice-${invoice.reference}.pdf`"
          class="tw-w-full"
          data-test="invoice-button"
          @view="viewFile"
          @download="downloadFile"
        >
          <ui-button
            variant="info"
            class="tw-w-full"
            data-test="pod-btn"
            @click="requestFile"
          >
            {{ $t('billing.buttons.download_invoice') }}
          </ui-button>
        </ctk-btn-file>
      </div>
    </b-collapse>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import PricingLines from './components/PricingLines/index.vue'
  import PricingLayout from './components/PricingLayout.vue'
  import CtkBtnFile from '@/components/CtkBtnFile/index.vue'

  /**
   * @module component - PricingInvoiceContent
   * @param {object} invoice
   * @param {string} currency
   * @param {boolean} [hasDownloadButton=false]
   */
  export default defineComponent({
    name: 'PricingInvoiceContent',
    components: {
      PricingLines,
      PricingLayout,
      CtkBtnFile
    },
    props: {
      invoice: {
        type: Object,
        default: Object,
        required: true
      },
      currency: {
        type: String,
        default: String,
        required: true
      },
      hasDetailOpenned: {
        type: Boolean,
        default: false
      },
      hasDownloadButton: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getCid'
      ]),
      fileUrl () {
        return `v2/companies/${this.getCid}/invoices/${this.invoice.uuid}/download`
      }
    },
    methods: {
      getCurrencyFormat (number) {
        return this.$options.filters.currency(number, this.currency, this._i18n.locale)
      },
      requestFile () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Invoices', 'Initiated CMR Download', this.invoice.uuid)
        }
      },
      viewFile () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Invoices', 'Confirmed CMR Preview', this.invoice.uuid)
        }
      },
      downloadFile () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Invoices', 'Confirmed CMR Download', this.invoice.uuid)
        }
      }
    }
  })
</script>

<style lang="scss" scoped>
.pricing-invoice-content {
  padding: 0 24px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.pricing-invoice-content.is-open {
  padding-block: 21px;
}
.pricing-invoice-content .title {
  font-weight: 400;
  margin: 0px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  --tw-text-opacity: 1;
  color: rgba(48, 49, 68, var(--tw-text-opacity));
}
.pricing-invoice-content__footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
</style>
