<template>
  <ctk-item
    :is-current="isCurrentMission"
    :class="{
      'has-selected': !!getCurrentMissionUuid,
      'is-current': !!isCurrentMission
    }"
    touchable-type="button"
    class="mission-item tw-w-full text-left tw-px-6"
    role="button"
  >
    <span class="tw-flex tw-flex-col">
      <mission-item-header
        :mission="mission"
        :is-late="isLate()"
        :is-current="isCurrentMission"
      />

      <div class="mission-item-content tw-flex tw-flex-row tw-justify-between">
        <mission-item-direction
          :mission="mission"
          class="tw-flex-1 tw-gap-y-4"
        />
      </div>
    </span>
  </ctk-item>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters } from 'vuex'

  import CtkItem from '@/components/CtkItem/index.vue'
  import MissionItemDirection from './_subs/MissionItemDirection/index.vue'
  import MissionItemCancellationDate from './_subs/MissionItemCancellationDate/index.vue'
  import MissionItemHeader from './_subs/MissionItemHeader/index.vue'

  /**
   * Single mission item
   * @module component - missionItem
   * @param {object} mission
   */
  export default defineComponent({
    name: 'MissionItem',
    components: {
      CtkItem,
      MissionItemDirection,
      MissionItemHeader,
      MissionItemCancellationDate
    },
    props: {
      mission: {
        type: Object,
        required: true
      },
      isCmrPending: {
        type: Boolean,
        default: null
      }
    },
    computed: {
      ...mapGetters('missions', [
        'getCurrentMissionUuid'
      ]),
      /**
       * @function isCurrentMission
       * @returns {boolean}
       */
      isCurrentMission () {
        return this.getCurrentMissionUuid === this.mission.uuid
      },
      /**
       * Returns true if the pickup and delivery are late.
       * If a parameter is passed, returns true if the direction is late.
       * @function isLate
       * @param {string} [direction]
       */
      isLate () {
        // @ts-ignore
        return (direction) => {
          // @ts-ignore
          const isLate = d => this.mission[d].state === 'late'

          return direction
            ? isLate(direction)
            : (isLate('pickup') || isLate('delivery'))
        }
      },
      /**
       * @function missionIsDelivered
       * @returns {boolean}
       */
      missionIsDelivered () {
        return (this.mission.pickup.state === 'completed' && this.mission.delivery.state === 'completed')
      },
      /**
       * Returns true if the mission is cancelled
       * @function isCancelled
       * @returns {boolean}
       */
      isCancelled () {
        return this.mission.state === 'cancelled'
      }
    }
  })
</script>

<style lang="scss" scoped>
.mission-item {
  border-radius: 0;
  border: none;
  padding-block: 21px !important;
  overflow: visible;
}
.mission-item:not(:last-of-type) {
  border-bottom: 1px solid #A4A4A4;
}
.mission-item.ctk-item--touchable.is-active {
  background: #F2F2F2;
}
.mission-item-content {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  grid-row-gap: 16px;
}
.mission-item-content .mission-item-direction, .mission-item-content .mission-item-dates, .mission-item-content .mission-item-driver-info, .mission-item-content .mission-item-tracking {
  grid-column: span 4;
}
.mission-item-content .mission-item-direction {
  min-height: 100px;
}
.mission-item.has-selected .mission-item-driver-info {
  flex: 0 0 40px;
}
.mission-item.is-late .mission-item-header {
  padding: 0 30px 0 22px;
  background: darken($danger, 5%);
  font-weight: 500;
}
.mission-item.is-late.is-current-mission {
  border: 1px solid darken($danger, 10%);
}
.mission-item.is-late.is-current-mission .mission-item-header {
  background: darken($danger, 10%);
}
.mission-item.is-cancelled .mission-item-driver-info {
  grid-column: span 2;
  grid-row: 3;
}
.mission-item.is-cancelled .mission-item-cancellation-date {
  grid-column: span 2 / 5;
}
.mission-item.is-current::v-deep .mission-item-header-reference {
  --tw-text-opacity: 1;
  color: rgba(103, 106, 108, var(--tw-text-opacity));
}
@media only screen {
  .mission-item.is-cancelled .mission-item-driver-info {
    grid-column: span 4;
  }
  .mission-item.is-cancelled .mission-item-cancellation-date {
    grid-column: span 4;
  }
  .mission-item.is-late .mission-item-header {
    padding: 0 16px;
  }
}
</style>
