<template>
  <div class="mission-detail-direction-inline-section tw-rounded tw-bg-white tw-flex-col tw-w-full">
    <div class="tw-flex">
      <mission-detail-section-title
        :title="$t('pickup') | capitalize"
        :reference="pickupReference"
        class="tw-w-1/2 tw-mr-12"
      >
        <ui-ctk-icon
          name="marker"
          class="tw-text-blue-500 tw-text-4xl"
        />
      </mission-detail-section-title>
      <mission-detail-section-title
        :title="$t('delivery') | capitalize"
        :reference="deliveryReference"
        class="tw-w-1/2 tw-ml-4"
      >
        <ui-ctk-icon
          name="marker"
          class="tw-text-green-500 tw-text-4xl"
        />
      </mission-detail-section-title>
    </div>
    <div class="tw-flex">
      <mission-detail-group-item
        :title="$t('missions.labels.address')"
        class="tw-mb-1 tw-mr-12 tw-w-1/2"
      >
        <mission-detail-direction-address
          :address="mission.pickup.address"
        />
      </mission-detail-group-item>
      <mission-detail-group-item
        :title="$t('missions.labels.address')"
        class="tw-mb-1 tw-w-1/2 tw-ml-4"
      >
        <mission-detail-direction-address
          :address="mission.delivery.address"
        />
      </mission-detail-group-item>
    </div>

    <div class="tw-flex">
      <div class="tw-w-1/2 tw-mr-12">
        <mission-detail-specificity-group>
          <mission-detail-specificity-item
            v-if="pickupAdditionalStep"
            :icon="'destination'"
            :title="$t('app.labels.additional_step.pickup')"
            :variant="'info'"
            :content="pickupAdditionalStep.comment"
            data-test="additional-step-pickup"
          />
          <mission-detail-specificity-item
            v-if="mission.pickup.comment"
            :icon="'comment'"
            :title="$t('comment')"
            :content="mission.pickup.comment"
            :variant="'info'"
            data-test="pickup-comment"
          />
          <mission-detail-accessibility
            :mission="mission"
            :direction="'pickup'"
            data-test="pickup-accessibility"
          />
        </mission-detail-specificity-group>
      </div>
      <div class="tw-w-1/2 tw-ml-4">
        <mission-detail-specificity-group>
          <mission-detail-specificity-item
            v-if="deliveryAdditionalStep"
            :icon="'destination'"
            :title="$t('app.labels.additional_step.delivery')"
            :variant="'primary'"
            :content="deliveryAdditionalStep.comment"
            data-test="additional-step-delivery"
          />
          <mission-detail-specificity-item
            v-if="mission.delivery.comment"
            :icon="'comment'"
            :title="$t('comment')"
            :content="mission.delivery.comment"
            :variant="'primary'"
            data-test="delivery-comment"
          />
          <mission-detail-accessibility
            :mission="mission"
            :direction="'delivery'"
            data-test="delivery-accessibility"
          />
        </mission-detail-specificity-group>
      </div>
    </div>

    <div class="tw-flex">
      <div class="tw-w-1/2 tw-mr-12">
        <contact-infos
          :mission="mission"
          :direction="'pickup'"
          class="tw-mb-6"
        />
      </div>

      <div class="tw-w-1/2 tw-ml-4">
        <contact-infos
          :mission="mission"
          :direction="'delivery'"
          class="tw-mb-6"
        />
      </div>
    </div>

    <div class="tw-flex">
      <div
        class="tw-w-1/2 tw-mr-12"
      >
        <mission-detail-timeslots
          :mission="mission"
          :direction="'pickup'"
        />
        <mission-detail-specificity-group
          v-if="!!pickupRdv"
        >
          <mission-detail-specificity-item
            :icon="'scheduled'"
            :title="$t('app.labels.rdv_option.pickup')"
            :variant="'info'"
            :content="pickupRdv"
            data-test="rdv-pickup"
          />
        </mission-detail-specificity-group>
      </div>
      <div
        class="tw-w-1/2 tw-ml-4"
      >
        <mission-detail-timeslots
          :mission="mission"
          :direction="'delivery'"
        />
        <mission-detail-specificity-group
          v-if="!!deliveryRdv"
        >
          <mission-detail-specificity-item
            :icon="'scheduled'"
            :title="$t('app.labels.rdv_option.delivery')"
            :variant="'primary'"
            :content="deliveryRdv"
            data-test="rdv-delivery"
          />
        </mission-detail-specificity-group>
      </div>
    </div>
    <div class="tw-flex">
      <div
        class="tw-w-1/2 tw-mr-12"
      >
        <mission-detail-options
          :mission="mission"
          :direction="'pickup'"
        />
      </div>
      <div
        class="tw-w-1/2 tw-ml-4"
      >
        <mission-detail-options
          :mission="mission"
          :direction="'delivery'"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { computed, defineComponent, toRefs } from '@vue/composition-api'

  import MissionDetailGroupItem from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailGroupItem'
  import MissionDetailSectionTitle from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailSection/MissionDetailSectionTitle'
  import MissionDetailSpecificityGroup from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailSpecificityGroup'
  import MissionDetailSpecificityItem from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailSpecificityGroup/_subs/MissionDetailSpecificityItem'
  import ContactInfos from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailDirectionsSection/_subs/ContactInfos'
  import MissionDetailDirectionAddress from '../MissionDetailDirectionAddress'
  import MissionDetailOptions from '../MissionDetailOptions'
  import MissionDetailTimeslots from '../MissionDetailTimeslots'
  import MissionDetailAccessibility from '../MissionDetailAccessibility'

  export default defineComponent({
    name: 'MissionDetailDirectionInlineSection',
    components: {
      MissionDetailGroupItem,
      MissionDetailDirectionAddress,
      MissionDetailSectionTitle,
      MissionDetailOptions,
      MissionDetailTimeslots,
      MissionDetailAccessibility,
      MissionDetailSpecificityGroup,
      MissionDetailSpecificityItem,
      ContactInfos
    },
    props: {
      mission: {
        type: Object,
        required: true
      }
    },
    setup (props) {
      const { mission } = toRefs(props)

      /**
       * @function getPriceLine
       * @param {string} lineKey
       * @returns {{
       *   key: string
       * } | undefined}
       */
      function getPriceLine (lineKey) {
        if (!mission.value.pricing || !mission.value.pricing.price_lines) return

        // @ts-ignore
        return mission.value.pricing?.price_lines?.find(line => line.key === lineKey)
      }

      const pickupRdv = computed(() => mission.value.pickup.meeting)
      const deliveryRdv = computed(() => mission.value.delivery.meeting)
      const pickupReference = computed(() => mission.value.pickup.reference)
      const deliveryReference = computed(() => mission.value.delivery.reference)
      const pickupAdditionalStep = computed(() => getPriceLine('pickup_additional_step'))
      const deliveryAdditionalStep = computed(() => getPriceLine('delivery_additional_step'))

      return {
        pickupRdv,
        deliveryRdv,
        pickupReference,
        deliveryReference,
        pickupAdditionalStep,
        deliveryAdditionalStep
      }
    }
  })
</script>

<style lang="scss" scoped>
.mission-detail-direction-inline-section {
  position: relative;
}
.mission-detail-direction-inline-section::after {
  --tw-border-opacity: 1;
  border-color: rgba(216, 216, 216, var(--tw-border-opacity));
  border-style: solid;
  border-top-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-right-width: 0px;
  position: absolute;
  content: '';
  height: 80%;
  height: calc(100% - 5rem);
  width: 1px;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-bottom: 0;
}
.mission-detail-direction-inline-section::v-deep .mission-detail-section-title__icon {
  position: relative;
}
.mission-detail-direction-inline-section::v-deep .mission-detail-section-title__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  min-height: 45px;
}
</style>
