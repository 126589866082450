
<template>
  <div
    :class="{'is-open': hasDetailOpenned}"
    class="pricing-invoice"
  >
    <pricing-invoice-header
      :invoice="invoice"
      :currency="currency"
      @toggle-detail="toggle"
    />

    <pricing-invoice-content
      :invoice="invoice"
      :currency="currency"
      :has-download-button="isUserShipper"
      :has-detail-openned="hasDetailOpenned"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import PricingInvoiceHeader from './_subs/PricingInvoiceHeader'
  import PricingInvoiceContent from './_subs/PricingInvoiceContent'

  export default {
    name: 'PricingInvoice',
    components: {
      PricingInvoiceHeader,
      PricingInvoiceContent
    },
    props: {
      invoice: {
        type: Object,
        default: Object,
        required: true
      },
      currency: {
        type: String,
        default: String,
        required: true
      }
    },
    data () {
      return {
        hasDetailOpenned: false
      }
    },
    computed: {
      ...mapGetters([
        'isUserShipper'
      ])
    },
    methods: {
      toggle (v) {
        this.hasDetailOpenned = v

        if (this.$matomo) {
          this.$matomo.trackEvent('Invoices', v
            ? 'Expanded Complementary Informations'
            : 'Reduced Complementary Informations', this.invoice.uuid)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.pricing-invoice {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(233, 233, 233, var(--tw-border-opacity));
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  margin-bottom: 1rem !important;
}
</style>
