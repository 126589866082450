<template>
  <div class="mission-detail tw-bg-white tw-relative tw-z-10">
    <div class="tw-flex-col tw-bg-white">
      <!-- header -->
      <header
        class="mission-detail__header tw-bg-white tw-relative tw-z-50 flex-fixed"
        data-test="header"
      >
        <mission-detail-header />
      </header>

      <!-- map -->
      <div
        class="mission-detail__content__map tw-top-0 tw-left-0 tw-w-full tw-z-0 tw-relative flex-fixed"
        data-test="map"
      >
        <call-center-btn />
        <mission-detail-map
          ref="map"
          :mission="getCurrentMission"
          :direction-focus="'travel'"
          :height="heightMap"
        />
      </div>
      <div
        class="tw-flex-col tw-pb-8"
        data-test="content"
      >
        <!-- Mission follow-up -->
        <div
          v-if="!isCancelled"
          class="mission-detail__content__section mission-detail-suivi tw-bg-white"
          data-test="follow-up"
        >
          <follow-up-status
            :mission="getCurrentMission"
            class="!tw-px-0"
          />
        </div>
        <!-- Contacts + driver -->
        <div
          class="mission-detail__content__section contacts-section-wrapper tw-w-full tw-flex tw-flex-wrap md:tw-gap-x-8 md:tw-items-start tw-bg-white tw-border-t tw-border-gray-400 tw-pl-10 tw-pr-6 md:tw-pr-10"
          data-test="contacts_driver"
        >
          <contact-infos
            :mission="getCurrentMission"
            :mission-state="getCurrentMissionState"
            class="contacts-section !tw-px-0"
            data-test="contacts"
          />
          <driver-infos
            :driver="getCurrentMissionDriver"
            :mission="getCurrentMission"
            class="contacts-section !tw-px-0"
            data-test="driver"
          />
        </div>
        <!-- Directions -->
        <mission-detail-directions-section
          :mission="getCurrentMission"
          class="mission-detail__content__section"
          data-test="directions"
        />
        <!-- Goods -->
        <goods-infos
          :mission="getCurrentMission"
          class="mission-detail__content__section"
          data-test="load"
        />
        <!-- Price -->
        <mission-detail-price-section
          v-if="!isCancelled"
          :billing="getBilling"
          class="mission-detail__content__section"
          data-test="price"
        />
        <!-- Billing info -->
        <billing-infos
          v-if="hasInvoiceNotPaid || !hasMissionCompleted"
          :billing="getBilling"
          data-test="billing-infos"
        />

        <!-- Invoices -->
        <div
          v-if="getBilling && isPODAvailable"
          class="invoices-wrapper"
          data-test="invoices"
        >
          <pricing-total
            v-if="hasMultiInvoices"
            id="multi-invoices"
            :label="$t('missions.your_fare')"
            :secondary-value="getCurrencyFormat(getBilling.invoices_amount)"
            :primary-value="$t('missions.price_incl_tax', {price: getCurrencyFormat(getBilling.invoices_vat_included_amount)})"
            class="tw-mb-6"
          />

          <pricing-total
            v-else
            :id="getBilling && !getBilling.invoices ? 'no-invoices' : 'solo-invoice'"
            :label="$t('missions.your_fare')"
            :primary-value="$t('missions.price_excl_tax', {price: getCurrencyFormat(getBilling.price)})"
            :without-arrow="typeof getBilling.lines === 'undefined' || !getBilling.lines.length"
            class="!tw-mb-6 !tw-p-4"
          />

          <template
            v-if="getBilling && hasBillingInvoices"
          >
            <pricing-invoice
              v-for="invoice in getBilling.invoices"
              :key="invoice.uuid"
              :invoice="invoice"
              :currency="getBilling.currency"
              :class="`${invoice.reference}`"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import MissionDetailHeader from './_subs/MissionDetailHeader'
  import CallCenterBtn from '@/views/Carriers/Missions/components/CallCenterBtn'
  import { mapGetters, mapActions } from 'vuex'
  import MissionDetailMap from './_subs/MissionDetailMap'

  import ContactInfos from './components/ContactInfos'
  import MissionDetailDirectionsSection from './components/MissionDetailDirectionsSection'
  import GoodsInfos from './components/GoodsInfos'
  import MissionDetailPriceSection from './components/MissionDetailPriceSection'
  import DriverInfos from './_subs/DriverInfos/index.vue'
  import FollowUpStatus from './components/FollowUpStatus'
  import BillingInfos from './_subs/BillingInfos'

  import PricingTotal from '@/components/CtkPricing/_subs/PricingTotal'
  import PricingInvoice from './components/PricingInvoice'

  export default {
    name: 'MissionDetail',
    props: {
      mission: {
        type: Object,
        required: true
      }
    },
    components: {
      MissionDetailHeader,
      CallCenterBtn,
      MissionDetailMap,
      ContactInfos,
      MissionDetailDirectionsSection,
      GoodsInfos,
      MissionDetailPriceSection,
      DriverInfos,
      FollowUpStatus,
      BillingInfos,
      PricingTotal,
      PricingInvoice
    },
    data () {
      return {
        heightMap: '284px'
      }
    },
    computed: {
      ...mapGetters(['isUserMono']),
      ...mapGetters('missions', [
        'getCurrentMission',
        'getCurrentMissionState',
        'getCurrentMissionDriver',
        'getBilling'
      ]),
      /**
       * Returns true if the user should have the map, according to the current tabs & other
       * misc parameters.
       * @function hasMap
       * @returns {boolean}
       */
      hasMap () {
        const isInformationTab = this.currentTab === 0
        const isStepsTab = this.currentTab === 1 && !this.hasDeliveryLate && !this.showCmr
        return (isInformationTab || isStepsTab) && this.heightMap
      },
      showCmr () {
        return ((this.hasDeliveryLate || this.getCurrentMission.delivery.state === 'completed') || this.getCurrentMissionState === 'completed')
      },
      /**
       * Returns true if the mission is late, and the expiration date is less than now + 1 day
       * @function hasDeliveryDate
       * @returns {boolean}
       */
      hasDeliveryLate () {
        const { delivery } = this.getCurrentMission
        const now = this.$moment()
        const deliveryExpirationDate = this.$moment(`${delivery.time_slot.date} ${delivery.time_slot.end_time}`).add(24, 'hours')

        return delivery.state === 'late' &&
          now >= deliveryExpirationDate
      },
      tabs () {
        return [
          { label: this.$t('missions.detail.tab.infos') },
          {
            label: this.$t(this.showCmr
              ? 'missions.detail.tab.cmr'
              : 'missions.detail.tab.steps'),
            active: !this.isCancelled
          },
          { label: this.$t('missions.detail.tab.billing') }
        ]
      },
      isCancelled () {
        return this.getCurrentMissionState === 'cancelled'
      },
      hasBillingInvoices () {
        if (!this.getBilling) return false
        return !!this.getBilling.invoices
      },
      invoiceCount () {
        return this.hasBillingInvoices && this.getBilling.invoices.length
      },
      hasMultiInvoices () {
        return this.invoiceCount > 1
      },
      hasInvoiceNotPaid () {
        let response = false
        if (this.invoiceCount) {
          this.getBilling.invoices.forEach(invoice => {
            if (!invoice.payment) {
              response = true
            }
          })
        }
        return response
      },
      hasMissionCompleted () {
        return this.getCurrentMissionStatus === 'completed'
      },
      isPODAvailable () {
        return this.getCurrentMissionState === 'completed' && this.getCurrentMission.proof_of_delivery.state === 'available'
      }
    },
    watch: {
      mission () {
        this.getCurrentMissionBilling()
      }
    },
    methods: {
      ...mapActions('missions', [
        'getCurrentMissionBilling'
      ]),
      getCurrencyFormat (number, currencyString) {
        return this.$options.filters.currency(number, currencyString || this.getBilling.currency, this._i18n.locale)
      }
    },
    created () {
      this.getCurrentMissionBilling()
    }
  }
</script>

<style lang="scss">
.mission-detail {
  background-color: #F2F2F2;
}
.mission-detail .tabs-bar-item {
  width: 100%;
}
.mission-detail .animated.bounce {
  animation-iteration-count: 2;
}
.mission-detail__content__map .mission-detail-map {
  min-height: 200px;
}
.mission-detail__content {
  overflow-x: auto;
}
.mission-detail__content .tab-info {
  background: #F2F2F2;
}
.mission-detail__content .tab-steps, .mission-detail__content .tab-info {
  z-index: 999;
  position: relative;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.mission-detail__content .tab-steps {
  background: #FFF;
  height: 235px !important;
  min-height: 235px !important;
  max-height: 235px !important;
  width: 100%;
}
.mission-detail .mission-detail__content__section {
  padding-left: 2.5rem;
  padding-right: 1.5rem;
  padding-top: 36px;
  padding-bottom: 36px;
  position: relative;
}
@media (min-width: 770px) {
  .mission-detail .mission-detail__content__section {
    padding-right: 2.5rem;
  }
}
.mission-detail .mission-detail__content__section::after {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 233, 233, var(--tw-bg-opacity));
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  content: '';
  bottom: 0;
  left: 1rem;
  right: 0;
  width: calc(100% - 4rem);
  height: 1px;
}
@media (min-width: 770px) {
  .mission-detail .mission-detail__content__section::after {
    left: 0;
    width: calc(100% - 5rem);
  }
}
.mission-detail .contacts-section {
  width: 100%;
}
@media (min-width: 770px) {
  .mission-detail .contacts-section {
    width: calc(50% - 2rem);
  }
  .mission-detail .contacts-section:has(+ .contacts-section) {
    margin-right: 2rem;
  }
  .mission-detail .contacts-section:has(+ .contacts-section)::after {
    --tw-border-opacity: 1;
    border-color: rgba(216, 216, 216, var(--tw-border-opacity));
    border-style: solid;
    border-top-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-right-width: 0px;
    position: absolute;
    content: '';
    height: 80%;
    height: calc(100% - 4.5rem);
    width: 1px;
    right: 0;
    left: 0;
    top: 2.25rem;
    bottom: 2.25rem;
    margin: auto;
    margin-bottom: 0;
  }
}
.mission-detail .invoices-wrapper {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding-left: 2.5rem;
  padding-right: 1.5rem;
}
@media (min-width: 770px) {
  .mission-detail .invoices-wrapper {
    padding-right: 2.5rem;
  }
}
</style>
