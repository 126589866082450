<template>
  <div class="call-center-btn tw-absolute tw-w-full p-3">
    <UiButton
      id="callCenterBtn"
      class="btn-call-center tw-shadow-md !tw-bg-white"
      variant="link"
      size="lg"
      @click="toggle"
    >
      <template #left-icon>
        <UiCtkIcon
          class="tw-text-red-500"
          name="support"
          data-test="icon"
        />
      </template>
    </UiButton>

    <!-- Popover -->
    <transition name="fade">
      <aside
        v-if="popover"
        class="call-center-btn-popover tw-rounded p-3"
      >
        <div class="call-center-btn-popover-header tw-flex tw-justify-between">
          <h1
            class="call-center-btn-popover-title tw-font-normal"
            v-text="$t('missions.a_problem')"
          />
          <UiButton
            :title="$t('close') | capitalize"
            variant="link"
            class="close"
            data-test="close"
            @click="close"
          >
            <template #left-icon>
              <UiCtkIcon
                name="close"
                data-test="icon"
              />
            </template>
          </UiButton>
        </div>
        <p
          class="tw-text-gray-700"
          data-test="support-text"
          v-text="$t('missions.contact_support', {
            ref: getCurrentMissionReference
          })"
        />

        <i18n
          data-test="email-text"
          path="missions.call_center_is_open_at_or_by_email"
          tag="p"
          class="tw-text-gray-700"
        >
          <template #phone>
            <UiLink
              :href="'tel:' + chronoinfos.phones.main.value"
              data-test="phone"
              v-text="chronoinfos.phones.main.text"
            />
          </template>
          <template #email>
            <UiLink
              :href="`mailto:${chronoinfos.emails.help}`"
              data-test="email"
              v-text="chronoinfos.emails.help"
            />
          </template>
        </i18n>
      </aside>
    </transition>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters } from 'vuex'

  import chronoinfos from '@/../config/chronoinfos'

  export default defineComponent({
    name: 'CallCenterBtn',
    data () {
      return {
        chronoinfos,
        popover: false
      }
    },
    computed: {
      ...mapGetters('missions', [
        'getCurrentMissionReference'
      ])
    },
    methods: {
      toggle () {
        this.popover = !this.popover
      },
      close () {
        this.popover = false
      }
    }
  })
</script>

<style lang="scss" scoped>

  .call-center-btn {
    z-index: 9;
    bottom: 0;

    &-popover {
      position: absolute;
      background-color: white;
      box-shadow: 0 3px 5px rgba(black, 0.12);
      bottom: 80px;
      left: 0;
      margin: 0 16px;

      &-title {
        font-weight: 300;
        font-size: 18px;
        margin: auto 0;
      }

      &::after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        bottom: -10px;
        border-style: solid;
        border-width: 10px 10px 0 10px;
        border-color: white transparent transparent transparent;
      }
    }
  }

</style>
