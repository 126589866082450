<template>
  <div class="billing-infos-contact">
    <div class="billing-infos-contact__invoice-to">
      <h3
        class="billing-infos-contact__invoice-to-title"
        v-text="$t('missions.billing_explanation.invoice_to')"
      />
      <address class="tw-mb-0">
        {{ chronoInfos.name }}, {{ chronoInfos.location.address }} - {{ chronoInfos.location.postalCode }}, {{ chronoInfos.location.city }}<br>
        {{ $t('missions.billing_explanation.vat') }} {{ chronoInfos.VAT }}<br>
      </address>
    </div>
    <div class="billing-infos-contact__email-to">
      <h3
        class="billing-infos-contact__email-to-title"
        v-text="$t('missions.billing_explanation.email_to')"
      />
      <UiLink
        :href="`mailto:${chronoInfos.emails.billing}`"
        target="_blank"
        data-test="email"
        v-text="chronoInfos.emails.billing"
      />
    </div>
  </div>
</template>

<script>
  import chronoinfos from '@/../config/chronoinfos'

  /**
   * @module component - billingInfosContact
   */
  export default {
    name: 'BillingInfosContact',
    data () {
      return {
        chronoInfos: chronoinfos
      }
    }
  }
</script>

<style lang="scss" scoped>
.billing-infos-contact {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 245, var(--tw-border-opacity));
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 1rem;
  --tw-text-opacity: 1;
  color: rgba(48, 49, 68, var(--tw-text-opacity));
  grid-gap: 1rem;
  gap: 1rem;
}
.billing-infos-contact__invoice-to-title, .billing-infos-contact__email-to-title {
  font-size: 0.875rem;
  margin-bottom: 0px;
}
</style>
