<template>
  <div class="mission-detail-group-item tw-flex tw-flex-col">
    <div
      v-text="title"
      class="mission-detail-group-item-title tw-uppercase tw-text-xs tw-text-secondary-text tw-mb-1 tw-font-normal"
      data-test="title"
    />
    <div
      :class="{
        'tw-text-blue-500': active,
        'tw-text-secondary-lighten': !active
      }"
      class="tw-font-medium tw-text-sm"
      data-test="content"
    >
      <slot />
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * @module component - MissionDetailGroupItem
   * @param {string} title
   */
  export default defineComponent({
    name: 'MissionDetailGroupItem',
    props: {
      title: {
        type: String,
        required: true
      },
      active: {
        type: Boolean,
        default: false
      }
    }
  })
</script>
