<template>
  <div class="pricing-invoice-header">
    <div class="tw-flex tw-flex-grow tw-justify-between tw-mr-1">
      <div class="tw-flex-col tw-justify-between">
        <div class="tw-flex tw-justify-between tw-items-center tw-gap-x-2">
          <span
            v-text="invoice.type.label"
            class="pricing-type tw-text-blue-500"
            data-test="label"
          />
        </div>

        <span
          class="pricing-ref"
          data-test="reference"
          v-text="invoice.reference"
        />
      </div>

      <div
        class="pricing-amount"
        data-test="pricing-amounts"
      >
        <span
          v-text="$t('price_excl_tax', {
            price: getCurrencyFormat(invoice.amount)
          })"
          data-test="amount-excl-tax"
        />
        <br>
        <span
          class="pricing-amount-price"
          v-text="$t('price_incl_tax', {
            price: getCurrencyFormat(invoice.vat_included_amount)
          })"
          data-test="amount-incl-tax"
        />
      </div>
    </div>

    <ui-button
      type="button"
      :title="$t('pricing.invoice.further_information')"
      :class="{'is-open': hasDetailOpenned}"
      class="pricing-invoice-header-button"
      variant="link"
      @click="toggleDetail"
      data-test="toggle-button"
    >
      <template #left-icon>
        <ui-material-icon
          :name="toggleButtonIcon"
          data-test="icon"
        />
      </template>
    </ui-button>
  </div>
</template>

<script>
  /**
   * @module component - PricingInvoiceHeader
   * @param {object} invoice
   * @param {string} currency
   */
  export default {
    name: 'PricingInvoiceHeader',
    props: {
      invoice: {
        type: Object,
        required: true
      },
      currency: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        hasDetailOpenned: false
      }
    },
    computed: {
      /**
       * @function state
       * @returns {string}
       */
      state () {
        const { state, payment } = this.invoice
        if (state !== 'paid') return 'pricing.invoice.waiting_payment'

        return payment && payment.method === 'credit_card'
          ? 'pricing.invoice.paid_cc'
          : 'pricing.invoice.paid'
      },
      toggleButtonIcon () {
        return this.hasDetailOpenned ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
      }
    },
    methods: {
      getCurrencyFormat (number) {
        return this.$options.filters.currency(number, this.currency, this._i18n.locale)
      },
      toggleDetail () {
        this.hasDetailOpenned = !this.hasDetailOpenned
        this.$emit('toggle-detail', this.hasDetailOpenned)
      }
    }
  }
</script>

<style lang="scss" scoped>
.pricing-invoice-header {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(233, 233, 233, var(--tw-border-opacity));
  border-style: solid;
  border-width: 0px;
  border-bottom-width: 1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  font-size: 0.875rem;
  line-height: 1.375;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 21px;
  padding-bottom: 21px;
}
.pricing-invoice-header .tw-text-red-500 {
  color: lighten($danger, 10%) !important;
}
.pricing-invoice-header .pricing-type {
  font-weight: 500;
}
.pricing-invoice-header .pricing-ref {
  --tw-text-opacity: 1;
  color: rgba(103, 106, 108, var(--tw-text-opacity));
}
.pricing-invoice-header .pricing-amount {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  text-align: right;
  --tw-text-opacity: 1;
  color: rgba(40, 118, 150, var(--tw-text-opacity));
}
.pricing-invoice-header .pricing-amount-price {
  font-weight: 500;
}
</style>
