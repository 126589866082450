<template>
  <div class="mission-detail-section-title">
    <div class="mission-detail-section-title__icon">
      <slot />
    </div>
    <div class="mission-detail-section-title__content">
      <h2
        v-text="title"
        class="tw-mb-0 tw-font-medium tw-text-xl"
        data-test="title"
      />
      <span
        v-if="hasReference"
        v-text="$t('missions.mission_detail.reference_number', { ref: reference })"
        class="mission-detail-section-title__reference"
      />
    </div>
  </div>
</template>

<script>
  import { defineComponent, toRefs, computed } from '@vue/composition-api'

  /**
   * @module component - MissionDetailSectionTitle
   * @param {string} title
   */
  export default defineComponent({
    name: 'MissionDetailSectionTitle',
    props: {
      title: {
        type: String,
        required: true
      },
      reference: {
        type: String,
        required: false,
        default: null
      }
    },
    setup (props) {
      const { reference } = toRefs(props)

      const hasReference = computed(() => !!reference.value)

      return {
        hasReference
      }
    }
  })
</script>

<style lang="scss" scoped>
.mission-detail-section-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  position: relative;
}
@media (min-width: 770px) {
  .mission-detail-section-title {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
}
.mission-detail-section-title__icon {
  height: 45px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mission-detail-section-title__content {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.mission-detail-section-title__reference {
  --tw-text-opacity: 1;
  color: rgba(103, 106, 108, var(--tw-text-opacity));
}
</style>
