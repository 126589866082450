<template>
  <mission-detail-section
    class="driver-infos"
    data-test="driver-infos"
  >
    <template
      #title
    >
      <mission-detail-section-title
        :title="title"
      >
        <img
          src="@/assets/img/icons/mission/team.svg"
          alt=""
          width="24"
          height="24"
          class="tw-mr-3"
          data-test="icon"
        >
      </mission-detail-section-title>
    </template>

    <div class="tw-px-4 tw-py-2 tw-flex tw-flex-col tw-bg-gray-200 tw-rounded">
      <div
        class="driver-infos__items tw-flex tw-min-w-70"
      >
        <mission-detail-group-item
          v-if="hasOwner"
          :title="ownerGroupItemTitle"
          class="driver-infos__item"
          data-test="owner-group-item"
        >
          <div class="tw-flex tw-gap-x-2">
            <ctk-avatar
              :user="owner"
              data-test="owner-avatar"
            />
            <div class="tw-flex-1 tw-flex tw-flex-col tw-justify-center">
              <div data-test="owner-name">
                {{ ownerFullName }}
              </div>
              <div
                class="owner-email"
                data-test="owner-email"
              >
                {{ owner.email }}
              </div>
            </div>
            <!-- change owner button -->
            <ui-button
              v-if="isChangeOwnerAvailable"
              variant="link"
              size="sm"
              class="edit-driver-btn"
              @click="openChangeOwnerDialog"
            >
              <template #left-icon>
                <ui-ctk-icon
                  name="edit-outlined"
                />
              </template>
            </ui-button>
          </div>
        </mission-detail-group-item>
      </div>

      <div
        class="driver-infos__items tw-flex tw-min-w-70"
      >
        <mission-detail-group-item
          :title="driverGroupItemTitle"
          class="driver-infos__item"
          data-test="driver-group-item"
        >
          <div class="tw-flex tw-gap-x-2">
            <ctk-avatar
              :user="driver"
              data-test="driver-avatar"
            />
            <div class="tw-flex-1 tw-flex tw-flex-col tw-justify-center">
              <div data-test="driver-name">
                {{ driverName }}
              </div>
              <div
                v-if="driverPhone"
                data-test="driver-phone"
              >
                {{ driverPhone }}
              </div>
            </div>
            <!-- change driver button -->
            <ui-button
              v-if="isChangeDriverAvailable"
              variant="link"
              size="sm"
              class="edit-driver-btn"
              @click="openChangeDriverDialog"
            >
              <template #left-icon>
                <ui-ctk-icon
                  name="edit-outlined"
                />
              </template>
            </ui-button>
          </div>
        </mission-detail-group-item>
      </div>
    </div>
  </mission-detail-section>
</template>

<script>
  import { computed, defineComponent, toRefs } from '@vue/composition-api'
  import { EventBus } from '@/services/EventBus'

  import useI18n from '@/composables/useI18n'
  import useStore from '@/composables/useStore'
  import usePhoneFilter from '@/composables/filters/usePhoneFilter'
  import useCapitalizeFilter from '@/composables/filters/useCapitalizeFilter'

  import MissionDetailSection from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailSection/index.vue'
  import MissionDetailSectionTitle from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailSection/MissionDetailSectionTitle/index.vue'
  import MissionDetailGroupItem from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailGroupItem/index.vue'

  import CtkAvatar from '@/components/CtkAvatar/index.vue'

  export default defineComponent({
    name: 'DriverInfos',
    components: {
      CtkAvatar,
      MissionDetailSection,
      MissionDetailSectionTitle,
      MissionDetailGroupItem
    },
    props: {
      driver: {
        type: Object,
        required: false,
        default: null
      },
      mission: {
        type: Object,
        required: true
      }
    },
    setup (props) {
      const i18n = useI18n()
      const { value: store } = useStore()
      const userInfos = store.getters['auth/getUserInfos']
      const isOwner = store.getters['auth/isCompanyOwner']
      const capitalize = useCapitalizeFilter()
      const phonize = usePhoneFilter()

      const { driver, mission } = toRefs(props)

      const owner = computed(() => mission.value?.owner ?? null)
      const hasOwner = computed(() => !!owner.value)
      const ownerFullName = computed(() => capitalize(`${owner.value.first_name ? owner.value.first_name : ''} ${owner.value.last_name ? owner.value.last_name : ''}`))

      const isUserMono = store.getters.isUserMono

      const chronotruckContact = computed(() => mission.value ? mission.value.shipper : undefined)

      const isChangeDriverAvailable = computed(() => {
        return (mission.value.state === 'planned' || mission.value.state === 'in_progress') && mission.value.delivery.state !== 'completed' && !isUserMono
      })

      const isChangeOwnerAvailable = computed(() => {
        return owner.value.uuid === userInfos.uuid || isOwner
      })

      const driverName = computed(() => {
        if (!driver.value) {
          return null
        }

        const {
          first_name: firstName,
          last_name: lastName
        } = driver.value
        return `${capitalize(firstName)} ${capitalize(lastName)}`
      })
      const driverPhone = computed(() => {
        return driver.value && driver.value.phone
          ? phonize(driver.value.phone)
          : i18n.value.t('missions.mission_detail.values.driver.phone.default')
      })

      const title = computed(() => capitalize(i18n.value.t('missions.mission_detail.label.team')))
      const driverGroupItemTitle = computed(() => capitalize(i18n.value.t('missions.mission_detail.label.driver')))
      const ownerGroupItemTitle = computed(() => capitalize(i18n.value.t('missions.mission_detail.label.dispatcher')))

      const openChangeDriverDialog = () => {
        EventBus.$emit('open-change-driver-dialog')
      }

      const openChangeOwnerDialog = () => {
        EventBus.$emit('open-change-owner-dialog')
      }

      return {
        chronotruckContact,
        driverGroupItemTitle,
        driverName,
        driverPhone,
        hasOwner,
        isChangeDriverAvailable,
        isChangeOwnerAvailable,
        openChangeDriverDialog,
        openChangeOwnerDialog,
        owner,
        ownerFullName,
        ownerGroupItemTitle,
        title
      }
    }
  })
</script>

<style lang="scss" scoped>
.driver-infos {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  white-space: nowrap;
}
.driver-infos__items {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  height: 100px;
  position: relative;
  grid-gap: 1rem;
  gap: 1rem;
}
.driver-infos__items .mission-detail-group-item {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -ms-flex-negative: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
}
@media (min-width: 770px) {
  .driver-infos__items .mission-detail-group-item {
    flex: 1 0 calc(18% - 1rem);
  }
}
.driver-infos__item {
  height: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.driver-infos .owner-email {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.driver-infos::v-deep .mission-detail-group-item-title {
  margin-left: 3rem;
}
</style>
